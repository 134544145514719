import { useEffect, useRef, useState } from 'react'
import Loader from 'react-loaders'
import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import emailjs from '@emailjs/browser'

const Contact = () => {
const [letterClass, setLetterClass] = useState('text-animate')
const refForm = useRef()

    useEffect(() => {
      return () => setTimeout(() => {
          setLetterClass('text-animate-hover')
      }, 3000)
  }, [])

  const sendEmail = (e) => {
      e.preventDefault()

      emailjs
      .sendForm(
          'service_f01e5t5',
          'template_4xakoiw',
          refForm.current,
          'kpPzT6fL-k2uVJsr7'
      )
      .then(
          () => {
              alert('Message successfully sent!')
              window.location.reload(false)
          },
          () => {
              alert('Failed to send the message, please try again!')
          }
      )
    

  }
    return (
        <>
        <div className='container contact-page'>
            <div className="text-zone">
                <h1>
                    <AnimatedLetters
                    letterClass={letterClass}
                    strArray={['C', 'o', 'n', 't', 'a', 'c', 't', ' ', 'm', 'e']}
                    idx={15}
                    />
                    </h1>
                    <p>
                     I am interested in cybersecurity and general software development internships. 
                     If you have any other questions, don't hesitate to contact me using the below form. 
                    </p>
                    <div className="contact-form">
                        <form ref={refForm} onSubmit={sendEmail}>
                            <ul>
                                <li className="half">
                                    <input type="text" name="name" placeholder="Name" required />
                                </li>
                                <li className="half">
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder="Email"
                                        required
                                    />
                                </li>
                                <li>
                                    <input 
                                    placeholder="Subject" 
                                    type="text"
                                    name="subject"
                                    required
                                    />
                                </li>
                                <li>
                                    <textarea 
                                    placeholder="Message"
                                    name="message"
                                    required
                                    ></textarea>
                                </li>
                                <li>
                                    <input type="submit" className='flat-button' value="SEND" />
                                </li>
                            </ul>
                        </form>  
                    </div>
                    </div>
            </div>
            <Loader type="pacman" />
        </>
    )
}

export default Contact